/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import { Document, Page, View } from "react-pdf"
//import ChairmanPDF from "../../img/investorcentre/chairman.pdf".
import ChairmanPDF from "./CS2023.pdf"
import IRNav from "../../components/IRNav"
import IRBG from "../../components/IRBG"
import HomeLogo from "../../components/HomeLogo"

export default class ChairmanStatementPage extends Component {
  state = { numPages: null, pageNumber: 1, totalPages: 4 }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }
  goToPrevPage = () =>
    this.setState((state) => ({
      pageNumber:
        state.pageNumber - 2 > 0 ? state.pageNumber - 2 : state.numPages - 1,
    }))
  goToNextPage = () =>
    this.setState((state) => ({
      pageNumber: state.pageNumber + 2 < 4 ? state.pageNumber + 2 : 1,
    }))

  render() {
    const { pageNumber, numPages } = this.state

    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Chairman's Statement - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Chairman's Statement
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-offset-2">
            <nav>
              <button onClick={this.goToPrevPage}>Prev</button>
              <button onClick={this.goToNextPage}>Next</button>
            </nav>

            <div className="pdf_div" style={{ width: 600 }}>
              <Document
                file={ChairmanPDF}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <div className="columns is-gapless">
                  <div className="column">
                    <Page pageNumber={pageNumber} width={500} />
                  </div>
                  <div className="column">
                    <Page pageNumber={pageNumber + 1} width={500} />
                  </div>
                </div>
              </Document>
            </div>

            <p>
              Page {pageNumber} of {numPages}
            </p>
            <nav>
              <button onClick={this.goToPrevPage}>Prev</button>
              <button onClick={this.goToNextPage}>Next</button>
            </nav>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}
